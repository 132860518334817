import React from 'react';
import Heading from 'components/atoms/Heading';
import PropertyFor from 'packages/inmeta-epi-react/components/PropertyFor';
import { COLUMN_ASIDE, COLUMN_FOOTER } from 'components/molecules/PageGrid';
import { useThemeConfig } from 'hooks/use-theme-config';
import * as styles from './BlockWrapper.scss';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const styleMap = {
  [COLUMN_ASIDE]: styles.asideTitle,
  [COLUMN_FOOTER]: styles.footerTitle,
};

const BlockWrapperTitle = ({ children, columnContext, style }) => {
  const themeConfig = useThemeConfig();
  const size =
    {
      [COLUMN_ASIDE]: Heading.sizes.heading5,
      [COLUMN_FOOTER]: themeConfig.footerHeadingSize,
    }[columnContext] || Heading.sizes.heading2;
  const titleStyle = style && style.color ? { color: style.color } : {};
  return (
    <PropertyFor
      property={children}
      component={Heading}
      componentProps={{
        level: columnContext === COLUMN_ASIDE ? 3 : 2,
        alt: false,
        bold: false,
        size,
        className: styleMap[columnContext] || styles.title,
        style: titleStyle,
      }}
    />
  );
};

export default BlockWrapperTitle;
